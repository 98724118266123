import React, { useState, useCallback, useEffect } from 'react';
import '@shopify/polaris/styles.css';

import { Modal, TextContainer, TextField } from '@shopify/polaris';

import Utils from '../utils';
import { shopDetails } from '../interfaces';

interface Props {
  isModalOpen: boolean,
  handleToggleModal: Function,
  setShopDetails: Function,
  shopDetails: shopDetails,
}

const PakkepostSettingsModal = ({
  isModalOpen, 
  handleToggleModal,
  setShopDetails,
  shopDetails,
}: Props) => {
  const [pakkepostUserIdValue, setPakkepostUserIdValue] = useState(shopDetails?.cargonizer?.apiKey || '');
  const handleChangePakkepostUserId = useCallback((newValue) => setPakkepostUserIdValue(newValue), []);

  useEffect(() => {
    setPakkepostUserIdValue(shopDetails?.cargonizer?.apiKey);
  }, [shopDetails])

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        handleToggleModal();
      }}
      title="Pakkepost settings"
      primaryAction={{
        content: 'OK',
        onAction: () => {
          if (pakkepostUserIdValue) {
            Promise.all([
              Utils.putPakkepostUserId({pakkepostUserId: pakkepostUserIdValue, callback: ({shopDetails} : { shopDetails: shopDetails}) => {
                setShopDetails(shopDetails);
              }})
            ]).then(() => {
              handleToggleModal();
            })
          }
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            handleToggleModal();
          },
        },
      ]}
    >
      <div className="set-modal-wrapper"> 
        <Modal.Section>
          <TextContainer>

          <TextField 
            label="Pakkepost User ID" 
            value={pakkepostUserIdValue} 
            placeholder="Value" 
            onChange={handleChangePakkepostUserId} 
          />
      
          </TextContainer>
        </Modal.Section>
      </div>
    </Modal>
  )
}

export default PakkepostSettingsModal;
