import React, { useState, useEffect } from 'react';
import '@shopify/polaris/styles.css';

import { DataTable, Tabs, Checkbox, Popover, ActionList, Button, Card } from '@shopify/polaris'

import WarningBanner from './WarningBanner';
import { Banner } from '@shopify/polaris';
import SendOrderModal from './SendOrderModal';

import { 
  Order,
  Tab,
  SelectedOrder,
  CustomError,
  shopDetails,
} from '../interfaces';

import { 
  ORDER_STATUS_ALL,
  ORDER_STATUS_UNFULFILLED,
  ORDER_STATUS_FULFILLED,
  IS_INFO_CHECKED,
 } from '../consts';
import Utils from '../utils';

interface Props {
  orders: Array<Order>,
  locationsList: Array<any>,
  errorsList: Array<CustomError>,
  setErrorsList: Function,
  shopDetails: shopDetails,
  handleLoadPrevOrders: () => void,
  handleLoadNextOrders: () => void,
  padingationLinks: {
    prevLink?: string;
    nextLink?: string;
  },
  setShowInfoPage: (flag: boolean) => void,
  isFetchingShopDetails:  boolean,
}

const OrdersTable = ({
  orders,
  locationsList,
  errorsList,
  setErrorsList,
  shopDetails,
  handleLoadPrevOrders,
  handleLoadNextOrders,
  padingationLinks,
  setShowInfoPage,
  isFetchingShopDetails,
}: Props) => {
  const [isInfoChecked, setIsInfoChecked]: any = useState(Utils.getCookie(IS_INFO_CHECKED))
  const [selectedOrders, setSelectedOrders]: any = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isAllOrdersSelected, setIsAllOrdersSelected] = useState(false);
  const [isAllFulfilledOrdersSelected, setIsAllFulfilledOrdersSelected] = useState(false);
  const [isAllUnfulfilledOrdersSelected, setIsAllUnfulfilledOrdersSelected] = useState(false);
  // Action
  const [isActionsListOpen, setIsActionsListOpen] = useState(false);
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const selectedOrdersValue: Array<SelectedOrder> = [];
    const firstOrder = selectedOrders[0];
    const selectedOrdersEmpty: boolean =  firstOrder === undefined;

    orders.forEach((order: Order) => {
      selectedOrdersValue.push({
        selected: false,
        status: order.status,
        id: order.id,
      })
    });
    if (selectedOrdersEmpty && orders.length && selectedOrdersValue.length) {
      setSelectedOrders(selectedOrdersValue)
    }
  }, [orders, selectedOrders]);

  const handleChangeSelectedTab = (selectedTabIndex: number) => {
    setSelectedTabIndex(selectedTabIndex);
  }

  const handleToggleSelectedOrder = (selectedOrderID: number) => {
    // toggle selected param for the selectedOrderID
    const selectedOrdersValue = [...selectedOrders];
    selectedOrdersValue[selectedOrderID] = {
      ...selectedOrdersValue[selectedOrderID],
      selected: !selectedOrdersValue[selectedOrderID].selected,
    }
    setSelectedOrders(selectedOrdersValue);
  }

  const handleChangeSelectedOrders = (value: boolean, status: string) => {
    const selectedOrdersValue: Array<SelectedOrder> = [];
    let isAllOrdersSelectedValue = false;
    let isAllFulfilledOrdersSelectedValue = false;
    let isAllUnfulfilledOrdersSelectedValue = false; 
    
    if (orders.length) {
      selectedOrders.forEach((selectedOrder: SelectedOrder, i: number) => {
        const orderFulfilled = selectedOrder.status === ORDER_STATUS_FULFILLED;
        const orderUnfulfilled = selectedOrder.status === ORDER_STATUS_UNFULFILLED;
  
        switch (status) {
          case ORDER_STATUS_ALL: 
            selectedOrdersValue[i] = {
              status: selectedOrders[i].status,
              selected: value,
              id: selectedOrder.id,
            }
            isAllOrdersSelectedValue = value;
            isAllFulfilledOrdersSelectedValue = isAllFulfilledOrdersSelected;
            isAllUnfulfilledOrdersSelectedValue = isAllUnfulfilledOrdersSelected;
            break;
          case ORDER_STATUS_UNFULFILLED: 
            if (orderUnfulfilled)  {
              selectedOrdersValue[i] = {
                status: selectedOrders[i].status,
                selected: value,
                id: selectedOrder.id,
              }
            } else {
              selectedOrdersValue[i] = {
                status: selectedOrders[i].status,
                selected: selectedOrders[i].selected,
                id: selectedOrder.id,
              }
            }
            isAllOrdersSelectedValue = isAllOrdersSelected;
            isAllFulfilledOrdersSelectedValue = isAllFulfilledOrdersSelected;
            isAllUnfulfilledOrdersSelectedValue = value;
            break; 
          case ORDER_STATUS_FULFILLED:
              if (orderFulfilled)  {
                selectedOrdersValue[i] = {
                  status: selectedOrders[i].status,
                  selected: value,
                  id: selectedOrder.id,
                }
              } else {
                selectedOrdersValue[i] = {
                  status: selectedOrders[i].status,
                  selected: selectedOrders[i].selected,
                  id: selectedOrder.id,
                }
              }
            isAllOrdersSelectedValue = isAllOrdersSelected;
            isAllFulfilledOrdersSelectedValue = value;
            isAllUnfulfilledOrdersSelectedValue = isAllUnfulfilledOrdersSelected;
            break; 
          default:
        }
      })
  
      setSelectedOrders(selectedOrdersValue);
      handleSelectAllOrders(isAllOrdersSelectedValue);
      handleSelectAllFulfilledOrders(isAllFulfilledOrdersSelectedValue);
      handleSelectAllUnfulfilledOrders(isAllUnfulfilledOrdersSelectedValue);
    }
  }

  const handleSelectAllOrders = (value: boolean) => {
    setIsAllOrdersSelected(value);
  }

  const handleSelectAllUnfulfilledOrders = (value: boolean) => {
    setIsAllUnfulfilledOrdersSelected(value);
  }

  const handleSelectAllFulfilledOrders = (value: boolean) => {
    setIsAllFulfilledOrdersSelected(value);
  }

  const handleToggleActionList = () => {
    setIsActionsListOpen(!isActionsListOpen);
  }

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsActionsListOpen(false);
  }

  let selectedAllValue = true;
  let selectedAllFulfilledValue = true;
  let selectedAllUnfulfilledValue = true;

  const rows: Array<Array<any>> = orders?.map((order: Order, i: number) => {
    const checkedValue: boolean = !!(selectedOrders[i]?.selected);

    return [ 
      <Checkbox
        label=""
        checked={checkedValue}
        onChange={() => {
          handleToggleSelectedOrder(i);
        }}
      />,
      orders[i].number,
      orders[i].name,
      <div className={`${orders[i].status}`}>
        {orders[i].status}
      </div>,
      orders[i].price,
      orders[i].freight,
      orders[i].date,
    ];
  })

  const tabs = [
    {
      id: 'all',
      content: 'All',
      tableData: '0',
      accessibilityLabel: 'All orders',
    },
    {
      id: 'unfulfilled',
      content: 'Unfulfilled', 
      tableData: '1',
      accessibilityLabel: 'Unfulfilled orders',
    },
    {
      id: 'fulfilled',
      content: 'Fulfilled orders',
      tableData: '2',
      accessibilityLabel: 'Fulfilled orders',
    },
  ];

  // Filter by fulfilled status for the tabs
  const selectedTab: Tab = tabs[selectedTabIndex];
  const selectedRows = rows.filter((row) => {
    return row[3].props.children === selectedTab.id || selectedTab.id === ORDER_STATUS_ALL;
  });
  // end

  // selected orders counter
  let selectedOrdersCounter = selectedOrders.filter((order: any) => order.selected).length;

  // update selectedAll flags in the state
  selectedOrders.forEach((selectedOrder: SelectedOrder, i: number) => {
    const orderStatus: string = orders[i].status;
    const orderSelected = selectedOrders[i].selected;

    const orderUnfulfilled = orderStatus === ORDER_STATUS_UNFULFILLED;
    const orderFulfilled = orderStatus === ORDER_STATUS_FULFILLED;

    if (selectedOrders) {
      if (!orderSelected) {
        selectedAllValue = false;
      }
      if (!orderSelected && orderFulfilled) {
        selectedAllFulfilledValue = false;
      }
      if (!orderSelected && orderUnfulfilled) {
        selectedAllUnfulfilledValue = false;
      }
    }
  });

  if (isAllOrdersSelected !== selectedAllValue) {
    handleSelectAllOrders(selectedAllValue);
  }

  if (isAllFulfilledOrdersSelected !== selectedAllFulfilledValue) {
    handleSelectAllFulfilledOrders(selectedAllFulfilledValue);
  }

  if (isAllUnfulfilledOrdersSelected !== selectedAllUnfulfilledValue) {
    handleSelectAllUnfulfilledOrders(selectedAllUnfulfilledValue);
  }
  // end

  let headerCheckbox: boolean = false;
  if (orders.length) {
    switch(selectedTabIndex) {
      // all orders
      case 0:
        headerCheckbox = isAllOrdersSelected;
        break;
      // unfulfilled orders
      case 1:
        headerCheckbox = isAllUnfulfilledOrdersSelected;
        break;
      // fulfilled orders
      case 2:
        headerCheckbox = isAllFulfilledOrdersSelected;
        break;
      default:
    }  
  }
 
  const actionsListActivator = (
    <Button 
      onClick={() => {
        handleToggleActionList();
      }} 
      disclosure
    >
      Actions
    </Button>
  );

  const headerCheckboxElement: any = (
    <Checkbox
      label=""
      checked={headerCheckbox}
      onChange={() => {
        if (orders.length) {
          switch(selectedTabIndex) {
            case 0:
              handleChangeSelectedOrders(!isAllOrdersSelected, ORDER_STATUS_ALL);
              break;
            case 1:
              handleChangeSelectedOrders(!isAllUnfulfilledOrdersSelected, ORDER_STATUS_UNFULFILLED);
              break;
            case 2:
              handleChangeSelectedOrders(!isAllFulfilledOrdersSelected, ORDER_STATUS_FULFILLED);
              break;
            default:
          }
        }
      }}
    />
  );

  const handleCloseInfoBanner = () => {
    Utils.setCookie(IS_INFO_CHECKED, 'true');
    setIsInfoChecked(true);
  }

  const cargonizerApiKeyIsSetted = shopDetails.cargonizer?.apiKey;
  const disableSending = !cargonizerApiKeyIsSetted || !selectedOrdersCounter;

  return (
    <div className="table-wrapper">
      {!cargonizerApiKeyIsSetted && !isFetchingShopDetails && (
        <WarningBanner text="Pakkepost User Id is not set. Please click on the dropdown on the right side, click on “Pakkepost settings” and add the userId. The userId you find
          on Pakkepost" />
      )}
      {!isInfoChecked && (
        <div className="polaris-info-banner">
          <Banner
            title="Welcome to Pakkepost Shopify plugin"
            action={{content: 'Learn more', onAction: () => {
              setShowInfoPage(true);
            }}}
            status="info"
            onDismiss={handleCloseInfoBanner}
          >
            <p>Learn about how Pakkepost Shopify plugin works and how to set it up</p>
          </Banner>
        </div>
      )}
      <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={(selectedTabIndex: number) => {
        handleChangeSelectedTab(selectedTabIndex)}
      }>
        <div className="table-actions" style={{display: selectedOrdersCounter ? '': 'none'}}>
          <div className="actions-counter">
            <Checkbox
              label=""
              checked={true}
            />
            <span className="selected-orders-counter">
              {selectedOrdersCounter}
              {' '}
              selected
            </span>
          </div>
          <Popover 
            active={isActionsListOpen} 
            activator={actionsListActivator}
            onClose={() => {
              handleToggleActionList();
            }}
          >
            <ActionList
              items={[
                {
                  content: 'Send items',
                  onAction: () => {
                    handleToggleModal();
                  },
                  disabled: disableSending,
                },
              ]}
            />
          </Popover>
        </div>
        <Card>
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              headerCheckboxElement,
              'Orderid',
              'Name',
              'Status',
              'Order amount',
              'Freight option',
              'Date',
            ]}
            rows={selectedRows} 
          />
        </Card>
      </Tabs>
      { isModalOpen && (
        <SendOrderModal 
          isModalOpen={isModalOpen} 
          handleToggleModal={handleToggleModal}
          selectedOrders={selectedOrders.filter((order: any) => order.selected)}
          locationsList={locationsList}
          errorsList={errorsList}
          setErrorsList={setErrorsList}
        />
      )}
      <div className="pagination-btns-wrapper">
        <div>
          {padingationLinks.prevLink && <Button onClick={handleLoadPrevOrders}>prev</Button>}
        </div>
        <div>
          {padingationLinks.nextLink && <Button onClick={handleLoadNextOrders}>next</Button>}
        </div>
      </div>
    </div>
  );
}

export default OrdersTable;
