import {
  API_URL,
  TOKEN,
  CHARGE_ID,
  ORDER_STATUS_UNFULFILLED,
  ORDER_STATUS_FULFILLED,
  SUBSCRIPTION_STATUS_IS_NOT_ACCEPTED,
  SELECTED_LOCATION,
  SELECTED_PRODUCT,
} from "./consts";

import {
  postOrdersArgs,
  getOrdersArgs,
  Order,
  getShopDetailsArgs,
  putPakkepostUserIdArgs,
  getLocationsArgs,
} from "./interfaces";

const Utils = {
  // cookie
  setCookie: (fieldName: string, fieldValue: string) => {
    let updatedCookie =
      encodeURIComponent(fieldName) + "=" + encodeURIComponent(fieldValue);
    document.cookie = updatedCookie + " ;Secure; SameSite=None";
  },
  getCookie: (fieldName: string) => {
    let matches = document.cookie.match(
      new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" +
          fieldName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },

  // local storage
  setLocalStorage: (fieldName: string, fieldValue: string | object) => {
    localStorage.setItem(fieldName, JSON.stringify(fieldValue));
  },
  getLocalStorage: (fieldName: string) => {
    const returnValue = localStorage.getItem(fieldName);
    return returnValue ? JSON.parse(returnValue) : returnValue;
  },
  removeLocalStorage: (fieldName: string) => {
    localStorage.removeItem(fieldName);
  },

  // requests
  getContextLink: ({ callback }: { callback: Function }) => {
    const token = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/shop/link`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response: any) => response.text())
      .then((response) => {
        if (response) {
          callback({ contextLink: response });
        }
      });
  },

  getActivate: (chargeId: String) => {
    const token = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${API_URL}/billing/activate?charge_id=${chargeId}`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors !== SUBSCRIPTION_STATUS_IS_NOT_ACCEPTED) {
          if (!Utils.isInIframe()) {
            Utils.getContextLink({
              callback: ({ contextLink }: { contextLink: string }) => {
                window.location.replace(contextLink);
              },
            });
          } else {
            window.location.reload();
          }
        }
      });
  },

  getShopDetails: ({ callback }: getShopDetailsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    fetch(`${API_URL}/shop`, {
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        callback({ shopDetails: response });
      })
      .catch(() => {
        callback({ shopDetails: {} });
      });
  },

  isInIframe: () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },

  postSubscribe: ({ callback }: { callback: Function }) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    const body = {
      returnUrl: window.location.href.split("?")[0],
    };

    fetch(`${API_URL}/billing/subscribe`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        Utils.removeLocalStorage(CHARGE_ID);
        if (response?.id) {
          Utils.setLocalStorage(CHARGE_ID, response.id);
          const redirectUrl = response.confirmationUrl;

          if (!Utils.isInIframe()) {
            window.location.replace(redirectUrl);
          } else {
            window.top.location.href = redirectUrl;
            setTimeout(() => {
              callback(redirectUrl);
            }, 5000);
          }
        }
      });
  },

  putPakkepostUserId: ({
    pakkepostUserId,
    callback,
  }: putPakkepostUserIdArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);
    requestHeaders.set("Content-Type", `application/json`);

    const body = {
      cargonizer: {
        apiKey: pakkepostUserId,
        senderId: pakkepostUserId,
      },
    };

    fetch(`${API_URL}/shop`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        Utils.removeLocalStorage(SELECTED_LOCATION);
        Utils.removeLocalStorage(SELECTED_PRODUCT);
        callback({ shopDetails: response });
      });
  },

  getLocations: ({ callback }: getLocationsArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    const url = new URL(`${API_URL}/shop/locations`);

    return fetch(`${url}`, {
      method: "GET",
      headers: requestHeaders,
    })
      .then((response) => response.json())
      .then((response) => {
        callback({ locationsList: response });
      });
  },

  postOrders: ({
    numberOfPackages,
    selectedOrders,
    finalCallback,
    productId,
    locationId,
    setErrorsList,
    setIsFetching,
    controller,
  }: postOrdersArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);
    requestHeaders.set("Content-type", "application/json");

    const body = {
      locationId: parseInt(locationId),
      productId,
      numberOfPackages: numberOfPackages,
      ids: selectedOrders.map((selectedOrder) => selectedOrder.id),
    };

    const { signal } = controller;

    fetch(`${API_URL}/orders/booking`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(body),
      signal,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.statusCode) {
          setErrorsList([response]);
          setIsFetching(false);
        } else {
          finalCallback(response);
        }
      })
      .catch((error) => {
        if (!error?.name?.includes("Abort")) {
          setErrorsList([error || "unhandled error"]);
        }
        setIsFetching(false);
      });
  },

  getOrders: ({
    callback,
    setPadingationLinks,
    requestLink = null,
  }: getOrdersArgs) => {
    const cookie = Utils.getCookie(TOKEN);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", `Bearer ${cookie}`);

    fetch(
      `${API_URL}/orders/?${
        requestLink
          ? `page_info=${requestLink}`
          : "status=any&financial_status=any&fulfillment_status=any"
      }&limit=50`,
      {
        headers: requestHeaders,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setPadingationLinks({
          prevLink: response.previous,
          nextLink: response.next,
        });

        const orders: Array<Order> = [];
        response.data.forEach((order: any) => {
          if (order) {
            orders.push({
              name: order.billingAddress?.name,
              id: order.id,
              number: order.orderNumber,
              status: order.fulfillmentStatus
                ? ORDER_STATUS_FULFILLED
                : ORDER_STATUS_UNFULFILLED,
              price: order.totalPrice,
              date: order.createdAt && Utils.formatDate(order.createdAt),
              shippingAddress: order.shippingAddress,
              lineItems: order.lineItems,
              customer: order.customer,
              freight:
                order.shippingLines?.lenght <= 1
                  ? order.shippingLines?.[0]?.title
                  : order.shippingLines
                      .map((el: { title: string }) => el.title)
                      .join(", "),
            });
          }
        });
        callback({ orders, success: true });
      })
      .catch(() => {
        callback({ orders: [], success: false });
      });
  },

  formatDate: (date: any) => {
    const formatDate = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = formatDate.getDate();
    const monthIndex = formatDate.getMonth();
    const year = formatDate.getFullYear();
    const hour = formatDate.getHours();
    const minute = formatDate.getMinutes();

    return `${hour}:${minute} ${monthNames[monthIndex]} ${day}, ${year}`;
  },
};

export default Utils;
