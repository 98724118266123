import React from "react";
import "@shopify/polaris/styles.css";

interface Props {
  setShowInfoPage: (flag: boolean) => void;
}

const InfoPage = ({ setShowInfoPage }: Props) => {
  const backBtnText = "<- Back to app";
  return (
    <div className="info-page">
      <button
        onClick={() => {
          setShowInfoPage(false);
        }}
        className="back-btn"
      >
        {backBtnText}
      </button>
      <h2>Pakkepost Shopify plugin instructions</h2>
      <h3>Preconditions</h3>
      <div className="row">
        <p className="max-width-475px">
          You need to have an account at Pakkepost. You can fix that by going to{" "}
          <a
            href="https://www.pakkepost.no/"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.pakkepost.no
          </a>{" "}
          and sign up. In addition you have to have set up your location on
          Shopify. Thats where you will send the packages from. Go to Shopify
          -&gt; Settings -&gt; Location -&gt; Add location to create a new
          location.
        </p>
      </div>
      <h3>Set up Pakkepost Shopify plugin</h3>
      <p className="max-width-475px">
        To set up Pakkepost, you have to log into www.pakkepost.no with your
        credentials you got from pakkepost, and then you have to go to “Min
        side” &gt; “Profil”
      </p>
      <img
        className="margin-top-15px"
        alt="info_image_1"
        src="/assets/info_image_1.png"
        height={137}
        width={719}
      />
      <p className="margin-top-15px max-width-655px">
        On the “Profil” page you find “Brukerid”. Add this user id to the app by
        clicking your shopname on the top right in the app and add it to
        “Pakkepost user id”
      </p>
      <h3>Using Pakkepost Shopify Plugin</h3>
      <p className="max-width-655px">
        To use this app, simply select an order (or many) and then click
        “Action” and “Send items”. Then you will get a link where you have to
        pay for order, and then you will get labels. Unfortunately, you need to
        manually fulfill the orders and copy tracking number. This will be
        automatically in the future.
      </p>
      <h3>Request features</h3>
      <p>
        If you see that you want to adjust the app for your needs, please
        contact us by e-mail
      </p>{" "}
      <div className="email-link">
        <a
          href="mailto:thomas@then.no"
          rel="noopener noreferrer"
          target="_blank"
        >
          thomas@then.no
        </a>
      </div>
    </div>
  );
};

export default InfoPage;
