import React, { useState, useEffect, Fragment } from "react";
import "@shopify/polaris/styles.css";

import {
  Select,
  Modal,
  TextContainer,
  Banner,
  TextField,
  Spinner,
} from "@shopify/polaris";
import Utils from "../utils";
import { Order, Product, CustomError } from "../interfaces";
import {
  SELECTED_PRODUCT,
  SELECTED_LOCATION,
  LIST_PRODUCTS_OPTIONS,
} from "../consts";

interface Props {
  isModalOpen: boolean;
  handleToggleModal: Function;
  selectedOrders: Array<Order>;
  locationsList: Array<any>;
  errorsList: Array<CustomError>;
  setErrorsList: Function;
}

const SendOrderModal = ({
  isModalOpen,
  handleToggleModal,
  selectedOrders,
  locationsList,
  errorsList,
  setErrorsList,
}: Props) => {
  const [controller, setController] = useState(new AbortController());
  const [paymentLink, setPaymentLink] = useState({} as any);
  const [isFetching, setIsFetching] = useState(false);

  const [numberOfPackages, setNumberOfPackages] = useState("1");
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    id: "",
  });
  const [selectedProduct, setSelectedProduct] = useState({
    label: "",
    value: "",
  });

  const handleSelectLocation = (locationIdentifier: string) => {
    const selectedLocationValue: Array<any> = locationsList.filter(
      (location: any) => location.id.toString() === locationIdentifier
    );
    if (selectedLocationValue[0]) {
      Utils.setLocalStorage(SELECTED_LOCATION, selectedLocationValue[0]);
      setSelectedLocation(selectedLocationValue[0]);
    }
  };

  const listLocationOptionsValue = locationsList?.map((location: any) => {
    return {
      label: location.name,
      value: location.id,
    };
  });

  const listProductsOptions = LIST_PRODUCTS_OPTIONS;

  const handleSelectProduct = (productIdentifier: string) => {
    const selectedProductValue: Array<Product> = listProductsOptions.filter(
      (product: Product) => product.value === productIdentifier
    );
    if (selectedProductValue[0]) {
      Utils.setLocalStorage(SELECTED_PRODUCT, selectedProductValue[0]);
      setSelectedProduct(selectedProductValue[0]);
    }
  };

  const handleChangeNumberOfPackages = (value: string) => {
    setNumberOfPackages(value.replace(/\D/g, ""));
  };

  useEffect(() => {
    const previousSelectedLocation = Utils.getLocalStorage(SELECTED_LOCATION);
    if (
      previousSelectedLocation &&
      selectedLocation.id !== previousSelectedLocation.id &&
      locationsList.find(
        (location) => location?.id === previousSelectedLocation?.id
      )
    ) {
      setSelectedLocation(previousSelectedLocation);
    }

    const previousSelectedProduct = Utils.getLocalStorage(SELECTED_PRODUCT);
    if (
      previousSelectedProduct &&
      selectedProduct.value !== previousSelectedProduct.value
    ) {
      setSelectedProduct(previousSelectedProduct);
    }
  }, [
    locationsList,
    selectedLocation,
    selectedLocation.id,
    selectedProduct.value,
  ]);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setErrorsList([]);
        handleToggleModal();
        setIsFetching(false);
      }}
      title={paymentLink.link ? "" : "Send orders"}
      primaryAction={{
        content: `${paymentLink ? "Checkout and pay" : "OK"}`,
        onAction: () => {
          if (paymentLink.link) {
            if (Utils.isInIframe()) {
              window.top.location.href = paymentLink.link;
            } else {
              window.location.replace(paymentLink.link);
            }
          } else if (selectedOrders.length === 0) {
            handleToggleModal();
          } else if (selectedProduct.value && selectedLocation.id) {
            setIsFetching(true);
            const newControllerValue = new AbortController();
            setController(newControllerValue);
            Utils.postOrders({
              numberOfPackages: parseInt(numberOfPackages, 10),
              locationId: selectedLocation.id,
              selectedOrders,
              finalCallback: (link: string) => {
                setPaymentLink(link);
                setIsFetching(false);
              },
              productId: parseInt(selectedProduct.value, 10),
              setErrorsList,
              setIsFetching,
              controller: newControllerValue,
            });
          }
        },
        disabled: isFetching,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            if (!paymentLink.link) {
              controller.abort();
              setErrorsList([]);
            }
            handleToggleModal();
            setIsFetching(false);
          },
        },
      ]}
    >
      <Modal.Section>
        {isFetching ? (
          <Spinner
            accessibilityLabel="Spinner example"
            size="large"
            color="teal"
          />
        ) : (
          <TextContainer>
            {paymentLink.link ? (
              <div className="paymentLink-modal">
                Orders are ready to be sent! Please go to this link to pay for
                your shipment and to print out labels
                <br />
                <a className="link" href={paymentLink.link}>
                  {paymentLink.link}
                </a>
              </div>
            ) : (
              <Fragment>
                {errorsList?.length ? (
                  <Banner title="Something went wrong" status="critical">
                    {errorsList.map((error: CustomError) => (
                      <p>
                        <span>
                          Errors:
                          <br />
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                error && error.message ? error.message : error,
                            }}
                          ></span>
                        </span>
                      </p>
                    ))}
                  </Banner>
                ) : null}
                <div className="sendorderModal-body">
                  <Select
                    label="Product"
                    options={listProductsOptions}
                    placeholder="Select..."
                    onChange={handleSelectProduct}
                    value={selectedProduct.value}
                  />
                  <Select
                    label="Location"
                    options={listLocationOptionsValue}
                    placeholder="Select..."
                    onChange={handleSelectLocation}
                    value={selectedLocation.id}
                  />
                  <TextField
                    label="Number of packages for each order"
                    value={numberOfPackages}
                    placeholder="1"
                    onChange={handleChangeNumberOfPackages}
                  />
                  <span className="text">
                    Please note that if you selected multiple orders, each of
                    the orders will have this amount of packages
                  </span>
                </div>
              </Fragment>
            )}
          </TextContainer>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default SendOrderModal;
